import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, DoCheck, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, Output, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoadingComponent } from '../../../common/components/loading/loading.component';
import { PaginatedResponse, SortOrder } from '../../../common/entities/paginated-response';
import { StyleService } from '../../../common/services/style/style.service';
import { CurafidaTranslatePipe } from '../../../translation/pluralization-translate.pipe';
import { TranslationModule } from '../../../translation/translation.module';
import {
    ActionEmitter,
    ActionType,
    ItemAdapterComponent,
    TableConfig,
    TableItem,
    TableUpdateValue,
} from '../../entities/table';
import { PipeTableLib } from '../../pipe/pipes-table-lib.module';
import { CurafidaColumnDefinitionComponent } from '../column-definition/curafida-column-definition.component';
import { LoadingStates } from './loading.process';

export type TableLoading = { success: boolean; processing: boolean };

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-table',
    templateUrl: './curafida-table.component.html',
    styleUrls: ['./curafida-table.component.scss'],
    standalone: true,
    imports: [
        PipeTableLib,
        NgClass,
        NgForOf,
        NgIf,
        NgStyle,
        IonicModule,
        CurafidaColumnDefinitionComponent,
        LoadingComponent,
        TranslationModule,
    ],
    providers: [CurafidaTranslatePipe],
})
export class CurafidaTableComponent<T> implements DoCheck {
    @Input() mobileAdapter: Type<ItemAdapterComponent>;

    // output option to open the item
    @Output() openDetail: EventEmitter<T> = new EventEmitter<T>();
    @Output() updateTable = new EventEmitter<TableUpdateValue>();
    @Output() setActionOnItem = new EventEmitter<ActionEmitter<T>>();
    @Output() updateContentOfTable = new EventEmitter<PaginatedResponse<T[]>>();
    @Output() isReorderUpdate = new EventEmitter<boolean>();

    protected SortOrder = SortOrder;

    // Keeps track of the current applied pagination options
    private currentPaginationState: TableUpdateValue = {};
    private readonly itemActionDebounce$ = new Subject<ActionEmitter<T>>();
    private differ: KeyValueDiffer<string, any>;

    protected paginationList: number[] = [];
    protected startPaginationToShowList: number[] = [];
    protected endPaginationToShowList: number[] = [];
    public isMobile = false;
    public mobileList: PaginatedResponse<T[]> = new PaginatedResponse<T[]>();

    constructor(
        private styleService: StyleService,
        private differs: KeyValueDiffers,
    ) {
        this.differ = this.differs.find({}).create();
        this.isMobile = this.styleService.isMobile$;
        this.itemActionDebounce$.pipe(debounceTime(300)).subscribe((value) => this.setActionOnItem.emit(value));
    }

    private _isLoading: LoadingStates;

    get isLoading(): LoadingStates {
        return this._isLoading;
    }

    @Input() set isLoading(value: LoadingStates) {
        this._isLoading = value;
        if (value.success && this.listTableConfig?.list) {
            this.initPagination(this.listTableConfig);
        }
    }

    private _listTableConfig: TableConfig<T[]>;

    get listTableConfig(): TableConfig<T[]> {
        return this._listTableConfig;
    }

    @Input()
    set listTableConfig(value: TableConfig<T[]>) {
        if (!value) return;
        value.itemSettings?.forEach((x) => {
            x.sortOrder = SortOrder.NONE;
        });
        this.currentPaginationState.limit = value?.list?.limit;
        if (this.styleService.isMobile$) {
            this.mobileList = value.list;
        } else {
            this._listTableConfig = value;
            this.initTable();
        }
    }

    /**
     * Updates and emits the current pagination state, including selected sort column and order.
     */
    private emitUpdateTableEvent(paginationOptions: TableUpdateValue): void {
        if (paginationOptions.limit >= 0) {
            this.currentPaginationState.limit = paginationOptions.limit;
        }
        if (paginationOptions.offset >= 0) {
            this.currentPaginationState.offset = paginationOptions.offset;
        }
        if (paginationOptions.sortBy) {
            this.currentPaginationState.sortBy = paginationOptions.sortBy;
        }
        if (paginationOptions.sortOrder) {
            this.currentPaginationState.sortOrder = paginationOptions.sortOrder;
        }
        if (paginationOptions.tableColumnId) {
            this.currentPaginationState.tableColumnId = paginationOptions.tableColumnId;
        }
        this.updateTable.emit(this.currentPaginationState);
    }

    private sortColumnPositions(tableItems: TableItem[]): TableItem[] {
        if (tableItems) {
            tableItems.sort((a, b) => {
                if (a.columnPosition < b.columnPosition) {
                    return -1;
                } else if (a.columnPosition > b.columnPosition) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
        }
        return tableItems;
    }

    openDetailPage(indexElement: number): void {
        if (this.listTableConfig.isOpenDetailEnable) {
            this.openDetail.emit(this.listTableConfig.list.items[indexElement]);
        }
    }

    updateList(offset: number, limit: number): void {
        this.emitUpdateTableEvent({
            offset: offset,
            limit: limit,
        });
    }

    emitActionItem(event, element, actionType: ActionType, itemSetting: TableItem): void {
        if (event) event.stopPropagation();
        if (actionType === ActionType.OPEN_NEW_PAGE || !actionType) {
            if (this.listTableConfig.isOpenDetailEnable) this.openDetail.emit(element);
        }
        if (actionType === ActionType.DELETE && element.hideDeleteButton) return;
        if (!this.isMobile && element[itemSetting.disabledProp]) return;
        if (itemSetting.disabled) return;
        this.itemActionDebounce$.next(new ActionEmitter<T>(actionType, element));
    }

    emitActionSelection(value: ActionType, element: T, itemSetting?: TableItem): void {
        if (!this.isMobile) if (element[itemSetting?.disabledProp]) return;
        this.emitActionItem(null, element, value, itemSetting);
    }

    private initPagination(value: TableConfig<T[]>): void {
        this.paginationList = [];
        const tablePaginationLength = value.list?.total / value.list?.limit ? value.list?.total / value.list?.limit : 0;
        this.paginationList = new Array(Math.ceil(tablePaginationLength)).fill(null).map((_, i) => i);

        this.startPaginationToShowList = [...this.paginationList];
        this.startPaginationToShowList = this.startPaginationToShowList.splice(0, 5);
        this.endPaginationToShowList = [...this.paginationList];
        this.endPaginationToShowList = this.endPaginationToShowList.splice(this.endPaginationToShowList.length - 5, 5);
    }

    previousPage(): void {
        this.emitUpdateTableEvent({
            offset: this.listTableConfig.list.offset - this.listTableConfig.list.limit,
        });
    }

    nextPage(): void {
        this.emitUpdateTableEvent({
            offset: this.listTableConfig.list.offset + this.listTableConfig.list.limit,
        });
    }

    /**
     * Emits the element index offset based on pageIndex
     * @param pageIndex - a number from 0 to (this.listTableConfig.list.limit.length - 1)
     */
    openTablePage(pageIndex: number): void {
        this.emitUpdateTableEvent({
            offset: pageIndex * this.listTableConfig.list.limit,
        });
    }

    doReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
        this.listTableConfig.list.items = ev.detail.complete(this.listTableConfig.list.items);
        let index = 0;
        for (const item of this.listTableConfig.list.items) {
            // @ts-ignore
            item.order = index;
            index++;
        }
        this.isReorderUpdate.emit(true);
        this.updateContentOfTable.emit(this.listTableConfig.list);
    }

    changeSortOrder(itemSetting: TableItem): void {
        if (itemSetting.sortBy) {
            for (const column of this.listTableConfig.itemSettings) {
                if (column.header !== itemSetting.header) column.sortOrder = SortOrder.NONE;
            }
            switch (itemSetting.sortOrder) {
                case SortOrder.NONE:
                    itemSetting.sortOrder = SortOrder.ASC;
                    break;
                case SortOrder.ASC:
                    itemSetting.sortOrder = SortOrder.DESC;
                    break;
                case SortOrder.DESC:
                    itemSetting.sortOrder = SortOrder.NONE;
                    break;
                default:
                    itemSetting.sortOrder = SortOrder.ASC;
                    break;
            }
            const sortOrder = itemSetting.sortOrder !== SortOrder.NONE ? itemSetting.sortOrder : null;
            this.emitUpdateTableEvent({
                sortBy: itemSetting.sortBy,
                sortOrder: sortOrder,
                offset: 0,
                tableColumnId: itemSetting.id,
            });
        }
    }

    async loadData(event): Promise<void> {
        event.target.complete();
        if (this.mobileList.offset + this.mobileList.limit <= this.listTableConfig.list.total) {
            this.updateList(this.mobileList.offset + this.listTableConfig.list.limit, this.mobileList.limit);
        }
    }

    async ngDoCheck(): Promise<void> {
        if (!this.listTableConfig) {
            return;
        }
        if (this.isMobile) {
            const change = this.differ.diff(this.listTableConfig.list.items);
            if (change) {
                if (this.listTableConfig.list.offset === 0) {
                    this.mobileList = this.listTableConfig.list;
                } else {
                    this.mobileList.offset = this.listTableConfig.list.offset;
                }
                change.forEachItem((item) => {
                    if (
                        this.mobileList &&
                        this.mobileList.count !== 0 &&
                        !this.mobileList.items.includes(item.currentValue)
                    ) {
                        this.mobileList.items.push(item.currentValue);
                        this.mobileList.count++;
                    }
                });
            }
        }
    }

    private initTable(): void {
        if (this.isLoading === undefined) {
            throw new Error(
                '[CurafidaTableComponent init error]: isLoading.processing or isLoading.success is required',
            );
        }
        if (this.listTableConfig === undefined) {
            throw new Error('[CurafidaTableComponent init error]: listTableConfig is required');
        }
        if (this.listTableConfig.emptyListLabel === undefined) {
            throw new Error('[CurafidaTableComponent init error]: emptyListLabel is required');
        }
        this.listTableConfig.itemSettings = this.sortColumnPositions(this.listTableConfig.itemSettings);
        this.initPagination(this.listTableConfig);
    }
}
