import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BrowserNavigationService {
    isMobile: boolean;

    constructor(
        private readonly platform: Platform,
        private readonly iab: InAppBrowser,
        private readonly router: Router,
    ) {
        this.platform.ready().then((source) => (this.isMobile = source === 'cordova'));
    }

    /**
     * In the browser loads the URL in the current browsing context.
     * In a mobile app loads the URL in the Cordova WebView if it is a whitelisted URL,
     * otherwise loads it in the InAppBrowser.
     * @param url - the URL to load
     */
    openTargetSelf(url: string) {
        this.open(url, '_self');
    }

    /**
     * In the browser loads the URL in a new tab (or window depending on the user settings).
     * In a mobile app loads the URL in the InAppBrowser.
     * @param url - the URL to load
     */
    openTargetBlank(url: string) {
        this.open(url, '_blank');
    }

    /**
     * In the browser loads the URL in a new tab (or window depending on the user settings).
     * In a mobile app loads the URL in the system default browser.
     * @param url - the URL to load
     */
    openTargetSystem(url: string) {
        this.open(url, '_system');
    }

    /**
     * Redirects in the current browsing context.
     * Mobile apps have https://localhost origin due to internal webview,
     * which is not resolvable on the internet and therefore are navigated by angular.
     */
    redirect(url: string) {
        const redirectionUrl = new URL(url);
        if (this.isMobile && redirectionUrl.host?.includes('localhost')) {
            this.router
                .navigateByUrl(redirectionUrl.toString().replace(redirectionUrl.origin, ''))
                .catch(console.error);
        } else {
            this.openTargetSelf(redirectionUrl.toString());
        }
    }

    private open(url: string, target: string) {
        if (this.isMobile) {
            this.iab.create(url, target);
        } else {
            window.open(url, target);
        }
    }
}
