import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../../../api';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { AccountType, LoginUserWithoutPasswordDto, PlainUserDto, User } from '../../../auth/entities/user';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { PatientCareRelationshipResource } from '../../components/user-main-info/patient-care-relationship.resource';
import { UserSearchParams } from '../../entities/user-search-params';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SupervisorService {
    public static options = { headers: new HttpHeaders(), withCredentials: false };

    constructor(
        protected http: HttpClient,
        private loadingService: LoadingService,
    ) {}

    async getSupervisorOfPatient(username: string): Promise<PatientCareRelationshipResource[]> {
        return this.http
            .get<PatientCareRelationshipResource[]>(`${ApiService.url}patients/${username}/supervisors`)
            .toPromise();
    }

    getSupervisedPatient(
        username: string,
        args: {
            offset?: number;
            limit?: number;
            disabled?: boolean;
            filter?: string;
            sortOrder?: SortOrder;
            sortBy?: SortBy | string;
            tagUuids?: string[];
            withTags?: boolean;
            accountType?: AccountType;
            emailVerified?: boolean;
            hasTherapy?: boolean;
            finished?: boolean;
            inactive?: boolean;
        } = {},
    ): Observable<PaginatedResponse<User[]>> {
        const url = new URL(`${ApiService.url}users/${username}/supervised`);
        if (args.offset) url.searchParams.set('offset', `${args.offset}`);
        if (args.limit || args.limit === 0) url.searchParams.set('limit', `${args.limit}`);
        if (args.filter) url.searchParams.set('filter', `${args.filter}`);
        if (args.sortBy) url.searchParams.set('sortBy', `${args.sortBy}`);

        if (args.sortOrder) {
            url.searchParams.set('sortOrder', `${args.sortOrder}`);
        } else {
            url.searchParams.set('sortOrder', SortOrder.ASC);
        }
        if (args.tagUuids?.length > 0) {
            for (const tagUuid of args.tagUuids) {
                url.searchParams.set('tagUuids', `${tagUuid}`);
            }
        }
        if (args.withTags != null) url.searchParams.set('withTags', `${args.withTags}`);
        if (args.disabled != null) url.searchParams.set('disabled', `${args.disabled}`);
        if (args.accountType) url.searchParams.set('accountType', `${args.accountType}`);
        if (args.emailVerified != null) url.searchParams.set('emailVerified', `${args.emailVerified}`);
        if (args.hasTherapy != null) url.searchParams.set('hasTherapy', `${args.hasTherapy}`);
        if (args.finished != null) url.searchParams.set('finished', `${args.finished}`);
        if (args.inactive != null) url.searchParams.set('inactive', `${args.inactive}`);

        return this.http.get<PaginatedResponse<User[]>>(url.toString());
    }

    getSupervisedPatients$(searchParams: EventEmitter<UserSearchParams>): Observable<PaginatedResponse<User[]>> {
        return searchParams.pipe(
            map((searchParams) => {
                const url = new URL(`${ApiService.url}users/${searchParams.username}/supervised`);
                if (searchParams.offset) url.searchParams.set('offset', `${searchParams.offset}`);
                if (searchParams.limit || searchParams.limit === 0) {
                    url.searchParams.set('limit', `${searchParams.limit}`);
                }
                if (searchParams.filter) url.searchParams.set('filter', `${searchParams.filter}`);
                if (searchParams.sortBy) url.searchParams.set('sortBy', `${searchParams.sortBy}`);

                if (searchParams.sortOrder) {
                    url.searchParams.set('sortOrder', `${searchParams.sortOrder}`);
                } else {
                    url.searchParams.set('sortOrder', SortOrder.ASC);
                }
                if (searchParams.tagUuids?.length > 0) {
                    for (const tagUuid of searchParams.tagUuids) {
                        url.searchParams.set('tagUuids', `${tagUuid}`);
                    }
                }
                if (searchParams.withTags != null) url.searchParams.set('withTags', `${searchParams.withTags}`);
                if (searchParams.disabled != null) url.searchParams.set('disabled', `${searchParams.disabled}`);
                if (searchParams.accountType) url.searchParams.set('accountType', `${searchParams.accountType}`);
                if (searchParams.emailVerified != null) {
                    url.searchParams.set('emailVerified', `${searchParams.emailVerified}`);
                }
                if (searchParams.hasTherapy != null) url.searchParams.set('hasTherapy', `${searchParams.hasTherapy}`);
                if (searchParams.finished != null) url.searchParams.set('finished', `${searchParams.finished}`);
                if (searchParams.inactive != null) url.searchParams.set('inactive', `${searchParams.inactive}`);

                return url;
            }),
            switchMap((url) => this.http.get<PaginatedResponse<User[]>>(url.toString())),
        );
    }

    async removeSupervisorOfPatient(username: string, supervisorName: string): Promise<void> {
        await this.http.delete(`${ApiService.url}patients/${username}/supervisors/${supervisorName}`).toPromise();
    }

    async postSupervisedLoginPatient(
        supervisorName: string,
        createUserDto: LoginUserWithoutPasswordDto,
        groupPaths?: string[],
    ): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${supervisorName}/supervised/patient`);
        if (groupPaths) {
            for (const path of groupPaths) {
                url.searchParams.append('groupPaths', path);
            }
        }
        return await this.http
            .post<User>(url.toString(), createUserDto)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async postSupervisedPlainPatient(
        supervisorName: string,
        plainUserDto: PlainUserDto,
        groupPaths?: string[],
    ): Promise<User> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}users/${supervisorName}/supervised/plainpatient`);
        if (groupPaths) {
            for (const path of groupPaths) {
                url.searchParams.append('groupPaths', path);
            }
        }
        return await this.http
            .post<User>(url.toString(), plainUserDto)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }
}
