import { Route, Routes } from '@angular/router';
import { RoutingSegment } from '../../common/entities/routing-segment';
import { LoginGuardService, MemberGuardService, RoleGuardService, RootGuardService } from '../../guard';
import { SplashGuardService } from '../../guard/splash-guard.service';
import { NoteCharacteristicAuthGuard } from '../../note/guards/note-tags.guard';

export const routeTemplates: Routes = [
    {
        path: '',
        canActivate: [RootGuardService],
        loadComponent: () => import('../../common/pages/default/default.page').then((c) => c.DefaultPage),
    },
    {
        path: 'signalr',
        canActivate: [SplashGuardService],
        loadChildren: () => import('../../measurement/pages/signalr/signalr.module').then((m) => m.SignalrPageModule),
    },
    {
        path: RoutingSegment.LOGIN,
        canActivate: [LoginGuardService],
        loadComponent: () => import('../../common/pages/login/login.page').then((m) => m.LoginPage),
    },
    {
        path: RoutingSegment.COMMON,
        canActivate: [SplashGuardService],
        loadChildren: () =>
            import('../../common/common-module-routing.module').then((m) => m.CommonModuleRoutingModule),
    },
    {
        path: RoutingSegment.INFO,
        loadComponent: () => import('../../common/pages/info/info.page').then((c) => c.InfoPage),
    },
    {
        path: RoutingSegment.SHOP,
        canActivate: [SplashGuardService],
        loadChildren: () => import('../../shop/shop-routing.module').then((m) => m.ShopRoutingModule),
    },
    {
        path: RoutingSegment.REGISTER,
        canActivate: [SplashGuardService],
        loadChildren: () => import('../../user/user-routing.module').then((m) => m.RegistrationRoutingModule),
    },

    {
        path: RoutingSegment.MEMBER,
        canActivate: [MemberGuardService],
        children: [
            {
                path: RoutingSegment.HOME,
                loadComponent: () => import('../../common/pages/home/home.page').then((c) => c.HomePage),
                canActivate: [RoleGuardService],
            },
            {
                path: `${RoutingSegment.HOME}/${RoutingSegment.MEETINGS}/${RoutingSegment.EXERCISE}/${RoutingSegment.DETAIL}/:id`,
                loadComponent: () =>
                    import(
                        './../../therapy/pages/manual/administration/course-exercise-manual-detail/course-exercise-manual-detail.page'
                    ).then((c) => c.CourseExerciseManualDetailPage),
                canActivate: [RoleGuardService],
            },
            {
                path: RoutingSegment.SHOP,
                canActivate: [SplashGuardService],
                loadChildren: () => import('../../shop/shop-routing.module').then((m) => m.ShopRoutingModule),
            },
            {
                path: RoutingSegment.MEETINGS,
                loadChildren: () => import('./../../meeting/meeting-routes.definition').then((c) => c.CourseRoutes),
            },
            {
                path: RoutingSegment.KIOSK,
                loadComponent: () =>
                    import('../../kiosk/pages/home-page/kiosk-home-page.component').then((c) => c.KioskHomePage),
            },
            {
                path: RoutingSegment.JOURNAL,
                loadComponent: () =>
                    import('../../journal/pages/patient-journal/patient-journal.page').then(
                        (c) => c.PatientJournalPage,
                    ),
            },
            {
                path: RoutingSegment.CHAT,
                loadChildren: () => import('../../chat/chat-routing.module').then((m) => m.ChatRoutingModule),
            },
            {
                path: RoutingSegment.CONSULTATION_MANAGEMENT + '/new',
                canActivate: [RoleGuardService],
                loadComponent: () =>
                    import('../../consultation/pages/consultation-creation/consultation-creation.page').then(
                        (c) => c.ConsultationCreationPage,
                    ),
            },
            {
                path: RoutingSegment.ORDER_MANAGEMENT + '/new',
                canActivate: [RoleGuardService],
                loadComponent: () =>
                    import('../../order/pages/order-creation/order-creation.page').then((c) => c.OrderCreationPage),
            },
            {
                path: RoutingSegment.SCREENING_MANAGEMENT,
                loadChildren: () =>
                    import('../../screening/pages/screening-creation/screening-creation.module').then(
                        (m) => m.ScreeningCreationModule,
                    ),
            },
            {
                path: RoutingSegment.QUESTIONNAIRE_MANAGEMENT,
                loadChildren: () =>
                    import('../../questionnaire/pages/questionnaire-management.module').then(
                        (m) => m.QuestionnaireManagementPageModule,
                    ),
            },
            {
                path: RoutingSegment.PATIENT_MANAGEMENT,
                children: [
                    {
                        path: RoutingSegment.CHAT,
                        loadChildren: () => import('../../chat/chat-routing.module').then((m) => m.ChatRoutingModule),
                    },
                    {
                        path: RoutingSegment.HOME,
                        loadComponent: () =>
                            import('../../common/pages/home-patient/home-patient-page.component').then(
                                (m) => m.HomePatientPage,
                            ),
                        canActivate: [RoleGuardService],
                    },
                    {
                        path: `${RoutingSegment.CONSULTATION_MANAGEMENT}/${RoutingSegment.DETAIL}/:username/:consultationId`,
                        loadComponent: () =>
                            import('../../consultation/pages/consultation-detail/consultation-detail.page').then(
                                (c) => c.ConsultationDetailPage,
                            ),
                    },
                    {
                        path: `${RoutingSegment.ORDER_MANAGEMENT}/${RoutingSegment.DETAIL}/:username/:orderId`,
                        loadComponent: () =>
                            import('../../order/pages/order-detail/order-detail.page').then((c) => c.OrderDetailPage),
                    },
                    {
                        path: RoutingSegment.TRAINING,
                        loadChildren: () =>
                            import('../../therapy/training-routing.module').then((m) => m.PatientTrainingRoutingModule),
                    },
                    {
                        path: RoutingSegment.MEASUREMENT_MANAGEMENT,
                        loadChildren: () =>
                            import('../../measurement/measurement-routing.module').then(
                                (m) => m.UserMeasurementRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.MEDICATION_MANAGEMENT,
                        loadChildren: () =>
                            import('../../medication/medication-routing.definition').then((c) => c.medicationRoutes),
                    },
                    {
                        path: RoutingSegment.MOBILITY,
                        loadChildren: () =>
                            import('../../mobility/mobility-routing.module').then((m) => m.MobilityRoutingModule),
                    },
                    {
                        path: RoutingSegment.LEARNING,
                        loadChildren: () =>
                            import('../../therapy/learning-routing.module').then((m) => m.UserLearningRoutingModule),
                    },
                    {
                        path: RoutingSegment.PROGRAM_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/program-routing.module').then((m) => m.ProgramRoutingModule),
                    },
                ],
            },
            {
                path: RoutingSegment.PATIENT_MANAGEMENT,
                loadChildren: () => import('../../user/patient-routing.module').then((m) => m.PatientRoutingModule),
            },
            {
                path: RoutingSegment.PROFILE,
                loadComponent: () => import('../../common/pages/profile/profile.page').then((m) => m.ProfilePage),
                canActivate: [RoleGuardService],
            },
            {
                path: RoutingSegment.CONCEPT,
                loadChildren: () =>
                    import('../../therapy/manual-routing.module').then((m) => m.AdministrationManualRoutingModule),
            },
            {
                path: RoutingSegment.KNOWLEDGE,
                loadChildren: () =>
                    import('../../knowledge/knowledge-routing.module').then((m) => m.KnowledgeMemberRoutingModule),
            },
            {
                path: RoutingSegment.ADMINISTRATION,
                children: [
                    {
                        path: RoutingSegment.USER,
                        loadChildren: () => import('../../user/user-routing.module').then((m) => m.UserRoutingModule),
                    },
                    {
                        path: RoutingSegment.ORGANISATION_MANAGEMENT,
                        children: [
                            {
                                path: RoutingSegment.LIST,
                                loadComponent: () =>
                                    import(
                                        '../../user/pages/organization-management/organization/organization-list.page'
                                    ).then((c) => c.OrganizationListPage),
                                canActivate: [RoleGuardService],
                            },
                            {
                                path: `${RoutingSegment.ORGANISATION}/:groupPath`,
                                loadComponent: () =>
                                    import(
                                        '../../user/pages/organization-management/group/organization-detail/organization-detail.page'
                                    ).then((c) => c.OrganizationDetailPage),
                                canActivate: [RoleGuardService],
                            },
                            {
                                path: `${RoutingSegment.GROUP}/:groupPath`,
                                loadComponent: () =>
                                    import(
                                        '../../user/pages/organization-management/group/group-detail/group-detail.page'
                                    ).then((c) => c.GroupDetailPage),
                                canActivate: [RoleGuardService],
                            },
                        ],
                    },
                    {
                        path: RoutingSegment.TRAINING,
                        loadChildren: () =>
                            import('../../therapy/training-routing.module').then(
                                (m) => m.AdministrationTrainingRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.TASK,
                        loadChildren: () =>
                            import('../../therapy/task-routing.module').then((m) => m.AdministrationTaskRoutingModule),
                    },
                    {
                        path: RoutingSegment.PROGRAM_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/program-routing.module').then((c) => c.administrationProgramRoutes),
                    },
                    {
                        path: RoutingSegment.MEASUREMENT_MANAGEMENT,
                        loadChildren: () =>
                            import('../../measurement/measurement-routing.module').then(
                                (m) => m.AdministrationMeasurementRoutingModule,
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./../../meeting/meeting-routes.definition').then(
                                (c) => c.CourseAdministrationRoutes,
                            ),
                    },
                    {
                        path: RoutingSegment.LEARNING,
                        loadChildren: () =>
                            import('../../therapy/learning-routing.module').then(
                                (m) => m.AdministrationLearningRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.NOTE_MANAGEMENT,
                        canActivate: [NoteCharacteristicAuthGuard],
                        loadChildren: () =>
                            import('../../note/pages/administration/note-administration.page').then(
                                (m) => m.NoteAdministrationPage,
                            ),
                    },
                    {
                        path: RoutingSegment.ANNOUNCEMENT,
                        loadComponent: () =>
                            import(
                                '../../announcement/pages/administration/announcement-administration-page.component'
                            ).then((m) => m.AnnouncementAdministrationPage),
                    },
                ],
            },
            {
                path: RoutingSegment.VIDEO_CONFERENCE,
                loadChildren: () =>
                    import('../../video-conference/video-conference-routing.module').then(
                        (m) => m.VideoConferenceRoutingModule,
                    ),
            },
        ],
    },
] satisfies Route[];
